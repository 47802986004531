import Box from '@material-ui/core/Box'
import { graphql } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CallFurthur from '../components/CallFurthur'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Getquote from '../components/Getquote'
import Layout from '../components/Layout'
import Location from '../components/Location'
// import SearchPostCard from '../components/SearchPostCard'
import SEO from '../components/seo'

const TabContent = ({
  content
}) => (
  <div className="fulltruckload__wrapper MuiBox-root-9">
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)

const SimpleTemplate = ({ data }) => {
  const { seo, careersPage: page } = data.wpPage
  const { hero, content } = page;
  
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <Hero
        type="primary"
        image={hero?.image?.localFile?.childImageSharp?.fluid}
        title={hero?.title}
      />
      <Container className="service-container" fluid>
        <Row>
          <Col xl={8}>
            <section className="service">
              <div className="makeStyles-root-1 tab-panel">
                <Box p={1}>
                  <TabContent
                        content={content}
                  />
                </Box>
              </div>
            </section>
          </Col>
          <Col className="service__col-right" xl={4}>
            <Getquote
              title="Get a quote today!"
              buttonLabel="Get a free estimate"
            />
            {/* <div className="service__col-right-wrapper">
              <div className="service__col-right-sub-heading">
                Read About
              </div>
              <SearchPostCard />
              <SearchPostCard />
              <SearchPostCard />
            </div> */}
          </Col>
        </Row>
      </Container>
      <CallFurthur />
      <Location />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query getSimpleAndDesktop($id: String) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      careersPage {
        hero {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        content
      }
    }
  }
`

export default SimpleTemplate
